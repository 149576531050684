import React from 'react'
import PropTypes from 'prop-types'
import Content from './Content'

const BudgetPageTemplate = ({ title, notice, content, contentComponent, budgets }) => {
  const PageContent = contentComponent || Content

  function Notice({notice}) {
    if(notice.title !== null) {
      return (
        <article className="message">
          <div className="message-header">
            {notice.title}
          </div>
          <div className="message-body">
            <p>{notice.message}</p>
          </div>
        </article>
      )
    }
    else {
      return (
        < ></ >
      )
    }
  }


  return (
    <section className="section section--gradient">
      <div className="content-spacer"></div>
      <div className="content container">
        <div className="columns">
          <div className="column is-7 is-offset-1">
            <div className="section">
              <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
                {title}
              </h1>
              <PageContent className="content" content={content} />
              <table className="table is-striped">
              <thead>
                <tr>
                  <td>Year</td>
                  <td>Revenue</td>
                  <td>Expenses</td>
                  <td>Net Income</td>
                  <td>Approved Budget</td>
                </tr>
              </thead>
              <tbody>
              {budgets.map( entry => (
                <tr>
                  <td>{entry.year}</td>
                  <td>$&nbsp;{entry.revenue.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
                  <td>$&nbsp;{entry.expenses.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
                  <td>$&nbsp;{(entry.revenue - entry.expenses).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
                  { entry.budget === null ? (<td></td>) : (<td><a className="button is-primary is-small" target="_blank" rel="noreferrer noopener" href={entry.budget.publicURL}>Approved Budget – {entry.year}</a></td>)}
                </tr>
              ))}
              </tbody>
              </table>
              <p><strong>Change in the District's Revenue from {budgets[1].year} to {budgets[0].year}:</strong> $&nbsp;{(budgets[0].revenue-budgets[1].revenue).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}&nbsp;&nbsp;|&nbsp;&nbsp;{((budgets[0].revenue-budgets[1].revenue)/budgets[1].revenue*100).toFixed(2)}%<br />
              <strong>Change in the District's Expenses from {budgets[1].year} to {budgets[0].year}:</strong> $&nbsp;{(budgets[0].expenses-budgets[1].expenses).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}&nbsp;&nbsp;|&nbsp;&nbsp;{((budgets[0].expenses-budgets[1].expenses)/budgets[1].expenses*100).toFixed(2)}%</p>
              <hr />
              <h4>Property Tax Revenue Budgeted for Maintenance & Operations and for Debt Service</h4>
              <table className="table is-striped">
              <thead>
                <tr>
                  <td>Year</td>
                  <td>Debt Service</td>
                  <td>Maintenance & Operations</td>
                </tr>
              </thead>
              <tbody>
              {budgets.map( entry => (
                <tr>
                  <td>{entry.year}</td>
                  <td>$&nbsp;{entry.debt.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
                  <td>$&nbsp;{entry.maintenance.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
                </tr>
              ))}
              </tbody>
              </table>
              <Notice notice={notice} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

BudgetPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

export default BudgetPageTemplate
